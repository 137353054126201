<template>
  <div class="mb-3">
    <div class="form-check text-muted">
      <input 
        class="form-check-input" 
        type="checkbox" value="" 
        v-uid ref="roundtripRef" 
        v-model="roundtrip"
        :disabled="!accepted">
      <label 
        class="form-check-label" 
        :class="!accepted ? 'opacity-25' : ''"
        :for="roundtripRef?.id">
        Make it a round trip
      </label>
    </div>
    <button class="d-block mx-auto btn btn-primary" 
      :class="!accepted ? 'opacity-25' : ''" 
      type="submit"
      :disabled="disabled"
      @click.prevent="createRoadtrip"
      >
      <span v-show="!loading">Let's GO!</span>
      <span v-show="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>   Loading...
      </span>
    </button>          
  </div>
</template>

<script setup lang="ts">
import { AuthControl } from '../authControl';
import { useCookieConsent } from '../composables/cookieConsent';
import { CreateRoadtripParams } from '../services/roadtripsService';
import { CreateStopParams } from '../services/stopsService';
import { injectRequired } from '../utilities/injectRequired';
import { useUserState } from "../composables/userState";
import { computed, ref } from 'vue';
import { Roadtrip } from '../models/roadtrip';
import { useServices } from '../composables/services';
import { useResultMessage } from '../composables/resultMessage';

const { 
  clearResult,
  setError
} = useResultMessage();

const authControl = injectRequired<AuthControl>('authControl');

const { accepted } = useCookieConsent();
const { userState } = useUserState(); 
const { roadtripsService } = useServices();

const disabled = computed(() => {
  return !props.canCreateRoadtrip 
    || !accepted.value 
    || loading.value 
})

const props = defineProps<{
  origin: CreateStopParams,
  destination: CreateStopParams,
  roadtripTemplateId?: string,
  canCreateRoadtrip: boolean
}>();

const loading = defineModel<boolean>('loading');
const roadtrip = defineModel<Roadtrip>('roadtrip');

const roundtripRef = ref();
const roundtrip = ref<boolean>(false);


async function createRoadtrip() {
  if (!props.origin.userProvidedName) throw Error("must have a start location to create Roadtrips.");
  if (!props.destination.userProvidedName) throw Error("must have a destination to create Roadtrips.");

  try {
    
    loading.value = true;
    clearResult();
    
    if(!userState?.value.isSignedIn) {
      await authControl.signInAnonymously()
    }

    const roadtripParams: CreateRoadtripParams = {
      origin: props.origin,
      destination: props.destination,
      roadtripTemplateId: props.roadtripTemplateId
    };

    if (roundtrip.value) {
      roadtripParams.roundtrip = roundtrip.value;
    }

    roadtrip.value = await roadtripsService.createRoadtrip(roadtripParams);
    
    // We changed the list, so kick off an update of the list
    // Note we don't wait for completion (i.e. no await), just kick it off
    roadtripsService.populateRoadtrips();

  } catch (error) {

    setError(error);

  } finally {

    loading.value = false;
  }

}
</script>